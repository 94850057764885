<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            Tenant Management
          </span>
        </li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Pencarian"
            icon="search"
            v-model="search"
            rounded
            @input="searchCompany"
          ></b-input>
        </b-field>
      </div>

      <div class="column is-2 is-offset-7 ">
        <b-button
          @click="downloadExcel"
          class="button button-import"
          accept=".xlsx"
          v-if="currentAdmin.role != null && currentAdmin.role.tenantExport"
        >
          <span class="file">
            <img
              :src="require('../../assets/Export.png')"
              width="17%"
              alt=""
              class="mr-2"
            />
            <span class="file">Export</span>
          </span>
        </b-button>
      </div>

      <div class="column is-12">
        <b-select
          v-model="perPage"
          placeholder="Per-Page"
          :value="perPage"
          @input="getCompanyManagement()"
        >
          <option
            v-for="option in perPages"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </b-select>
      </div>

      <div class="column">
        <b-table
          :data="companies"
          :loading="loading"
          paginated
          backend-pagination
          :total="rows"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column label="No" v-slot="props">
            {{ (page - 1) * perPage + props.index + 1 }}
          </b-table-column>

          <b-table-column
            field="company name"
            sortable
            label="Nama Tenant"
            v-slot="props"
          >
            {{ props.row.companyName }}
          </b-table-column>

          <b-table-column
            field="package name"
            label="Jenis Paket"
            v-slot="props"
          >
            {{ props.row.billingName }}
          </b-table-column>

          <b-table-column field="joined" label="Bergabung" v-slot="props">
            {{ props.row.joined }}
          </b-table-column>

          <b-table-column
            field="active Date"
            sortable
            label="Mulai Berlangganan"
            v-slot="props"
          >
            {{ props.row.activeDate }}
          </b-table-column>

          <b-table-column
            field="remain"
            sortable
            label="Sisa Masa aktif Langganan"
            v-slot="props"
          >
            <span
              v-if="
                props.row.remain.remainDay >= 0 && props.row.billingName !== ''
              "
            >
              {{ props.row.remain.remainDay }} hari
            </span>
            <span class="has-text-red" v-else>Expire</span>
          </b-table-column>

          <b-table-column label="Aksi" v-slot="props">
            <router-link
              :to="`/tenant/${props.row.id}`"
              v-if="currentAdmin.role != null && currentAdmin.role.tenantDetail"
            >
              <img
                :src="require('../../assets/Edit.png')"
                alt=""
                width="20"
                height="20"
              />
            </router-link>
          </b-table-column>
          <template slot="bottom-left">
            <div class="has-text-left">
              Page {{ page }} From
              {{
                Math.ceil(rows / perPage) == '0'
                  ? '1'
                  : Math.ceil(rows / perPage)
              }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      companies: [],
      total: 0,
      loading: false,
      sortField: 'id',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      page: 1,
      perPage: 10,
      search: '',

      perPages: [
        { text: '5', value: '5' },
        { text: '10', value: '10' },
        { text: '25', value: '25' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
      ],
    }
  },
  computed: {
    ...mapGetters(['currentAdmin']),

    rows() {
      return this.total
    },
  },
  created() {
    this.getCompanyManagement()
  },
  methods: {
    searchCompany: debounce(function() {
      this.getCompanyManagement()
    }, 500),
    getCompanyManagement: function() {
      const params = [
        `sort_by=${this.sortField}`,
        `order=${this.sortOrder}`,
        `page=${this.page}`,
        `per_page=${this.perPage}`,
        `search=${this.search}`,
      ].join('&')
      this.loading = true

      this.$store.dispatch('getCompanyManagement', params).then(response => {
        if (response.data != null) {
          this.companies = response.data
        } else {
          this.companies = []
        }
        this.total = response.total
        this.loading = false
      })
    },
    onPageChange(page) {
      this.page = page
      this.getCompanyManagement()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.getCompanyManagement()
    },
    downloadExcel() {
      this.$store.dispatch('downloadExcelTenant')
    },
  },
}
</script>

<style>
.button-import {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
}
</style>
